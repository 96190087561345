import "core-js/stable";
import "regenerator-runtime/runtime";
import "./app.scss";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Menu from "./js/Menu";
import Slider from "./js/Slider";
import Popup from "./js/Popup";
import Headroom from "headroom.js";
import 'alpinejs';

gsap.registerPlugin(ScrollTrigger);

document.addEventListener("DOMContentLoaded", () => {
    const menu = new Menu();
});
