console.log("DOM is loaded!");

var slides = document.querySelectorAll('#slides .slide');
var currentSlide = 0;
var slideInterval = setInterval(nextSlide, 8000);
var imageTitleElem = document.getElementById('imageTitle');

function normalizeTitle(title) {
    const prefix = 'innodry solution:';
    title = title.toLowerCase();
    if (title.includes(prefix)) {
        title = title.split(prefix)[1]; 
    }
    return title.trim(); 
}

function checkTitle(currentTitle) {
    var titles = document.querySelectorAll('.service-title');

    console.log("Current title:", currentTitle);
    console.log("Titles in the DOM:");
    titles.forEach((title, index) => {
        const titleText = title.textContent.trim();
        console.log(`${index}: ${titleText}`);
    });

    // Normalize the current title for comparison
    const normalizedCurrentTitle = normalizeTitle(currentTitle);

    console.log("Checking for matches in the DOM:");
    const matchingTitles = [];
    
    // First, remove the 'hidden' class from all titles
    titles.forEach((title) => {
        title.parentElement.classList.remove('hidden');
    });

    // Now, check for titles that match the current one and hide them
    titles.forEach((title, index) => {
        const titleText = normalizeTitle(title.textContent.trim()); 
        console.log(`${index}: ${titleText}`);
        if (titleText === normalizedCurrentTitle) {
            matchingTitles.push(title);
        }
    });

    if (matchingTitles.length > 0) {
        console.log("Confirmation: A match has been found.");
        matchingTitles.forEach((title, index) => {
            console.log(`${index}: ${title.textContent.trim()}`);
            title.parentElement.classList.add('hidden');
        });
    } else {
        console.log("No matching titles found.");
    }
}


function updateServiceButton(url) {
    var serviceButton = document.getElementById('service-button');
    serviceButton.href = url;
}

function updateProgressBar() {
    var progressBar = document.getElementById('progressBar');
    var percentage = (currentSlide / (slides.length - 1)) * 100;
    progressBar.style.width = percentage + '%';
}

function updateTitle(title) {
    imageTitleElem.textContent = title; // Ensure 'title' contains the correct text
}

function nextSlide() {
    slides[currentSlide].classList.remove('showing');
    currentSlide = (currentSlide + 1) % slides.length;
    slides[currentSlide].classList.add('showing');
    var newTitle = slides[currentSlide].getAttribute('data-title');
    var currentUrl = slides[currentSlide].getAttribute('data-url');
    
    updateTitle(newTitle);
    checkTitle(newTitle); // Check the title when the slide changes
    updateServiceButton(currentUrl);
    updateProgressBar();
}

function initSlider() {
    slides[currentSlide].classList.add('showing');
    var currentTitle = slides[currentSlide].getAttribute('data-title');
    var currentUrl = slides[currentSlide].getAttribute('data-url');
    updateTitle(currentTitle);
    updateServiceButton(currentUrl);
    updateProgressBar();

    console.log(`Current title during initialization: "${currentTitle}"`);
    checkTitle(currentTitle);  // Updated this line
}

window.sliderInstance = {
    changeSlide: function(index, imageUrl, title) {
        var currentUrl = slides[currentSlide].getAttribute('data-url');
        clearInterval(slideInterval);
        slides[currentSlide].classList.remove('showing');
        currentSlide = index;
        slides[currentSlide].style.backgroundImage = `url('${imageUrl}')`;
        slides[currentSlide].classList.add('showing');
        updateTitle(title);
        updateServiceButton(currentUrl);
        checkTitle(title);  // Updated this line
        updateProgressBar();
        slideInterval = setInterval(nextSlide, 8000);
    }
};

function hideSimilarTitles(currentTitle) {
    var titles = document.querySelectorAll('.service-title');
    var matchingTitles = []; // Array to store matching titles

    // First, let's find if there are any matching titles and store them in the array
    titles.forEach(function(titleElem) {
        var titleData = titleElem.textContent.trim();
        if (titleData === currentTitle.trim()) {
            matchingTitles.push(titleElem);
        }
    });

    if (matchingTitles.length > 0) {
        console.log("Confirmation: A match has been found.");
        // If there are matching titles, we hide them
        matchingTitles.forEach((title, index) => {
            console.log(`${index}: ${title.textContent.trim()}`);
            title.parentElement.classList.add('hidden');
        });
    } else {
        console.log("No matching titles found.");
        // If no titles match, we ensure all titles are visible
        titles.forEach(function(titleElem) {
            titleElem.parentElement.classList.remove('hidden');
        });
    }
}


initSlider();
