console.log("Popup is loaded!");

const lightbox = document.createElement('div');
lightbox.id = 'lightbox';
lightbox.classList.add('hidden', 'lightbox');
lightbox.innerHTML = `
  <img id="lightbox-image" src="" alt="Enlarged Image"/>
  <span id="close-lightbox" class="close-lightbox">&times;</span>
`;
document.body.appendChild(lightbox);

document.querySelectorAll('.pswp-gallery a').forEach(link => {
    link.addEventListener('click', function(e) {
        e.preventDefault();
        console.log("Link clicked!", e.currentTarget.href);
        document.getElementById('lightbox-image').src = e.currentTarget.href;
        lightbox.classList.remove('hidden');
    });
});

document.getElementById('close-lightbox').addEventListener('click', function() {
    lightbox.classList.add('hidden');
});
