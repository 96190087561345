const menuButton = document.querySelector('.menu-button');
const mobileMenu = document.querySelector('.mobile-menu');
const navBackground = document.querySelector('.navbar-background');

// Set initial padding on DOM load
if (navBackground) {
  navBackground.style.padding = '47px 0';
}

if (menuButton && mobileMenu) {
  menuButton.addEventListener('click', () => {
    mobileMenu.classList.toggle('open');
    if (mobileMenu.classList.contains('open')) {
      navBackground.style.backgroundColor = 'rgba(0, 0, 0, 0.8)';
      navBackground.style.padding = '20px 0';
    } else {
      navBackground.style.backgroundColor = 'rgba(0, 0, 0, 0)';
      navBackground.style.padding = '47px 0';
    }
  });
}

window.addEventListener('scroll', () => {
  const scrollPosition = window.scrollY;
  if (scrollPosition > 100) {
    navBackground.style.backgroundColor = 'rgba(0, 0, 0, 0.8)';
    navBackground.style.padding = '20px 0';
  } else {
    navBackground.style.backgroundColor = 'rgba(0, 0, 0, 0)';
    navBackground.style.padding = '47px 0';
  }
});
